import { data } from 'jquery';
import { EventBus } from '../../packs/application';
import { displayToast } from '../../utils/functions'
import AxiosIncrustationService from '../../services/incrustation/incrustation';
import AxiosLanguagesService from '../../services/languages/languages';
import AxiosVideosService from '../../services/video/video-index';
import AxiosProjectService from '../../services/project/project'

export const projectsStore = {
  namespaced: true,
  state: {
    projects: [],
    languages: [],
    languagesUsed: [],
    translationLanguages: [],
    projectsSelected: [],
    subtitlesSelected: [],
    subtitlesToIncrust: [],
    subtitleToTranslate: {
      video_id: null,
      original_subtitle_id: null,
      dest_lang: []
    },
    subtitleForNewVideo: [],
    videos: [],
    numberOfVideos: 0,
    incrustations: [],
    numberOfIncrustations: 0,
    userProjectsNames: [],
    cpl_selected: 40,
    line_selected: 2, 
  },
  getters: {
    projects_selected: state => state.projectsSelected,
    languages: state => state.languages,
    languages_used: state => state.languagesUsed,
    subtitles_selected: state => state.subtitlesSelected,
    subtitles_to_incrust: state => state.subtitlesToIncrust,
    subtitle_to_translate: state => state.subtitleToTranslate,
    translation_languages: state => state.translationLanguages,
    translation_language_selected: state => state.subtitleToTranslate.dest_lang,
    subtitles_for_new_video: state => state.subtitleForNewVideo,
    videos: state => state.videos,
    number_of_videos: state => state.numberOfVideos,
    incrustations: state => state.incrustations,
    number_of_incrustations: state => state.numberOfIncrustations,
    user_projects_names: state => state.userProjectsNames,
    cpl_selected: state => state.cpl_selected,
    line_selected: state => state.line_selected
  },
  mutations: {
    SET_LANGUAGES(state, languages) {
      if(state.languages.length == 0) languages.map(language => { state.languages.push(language) });
    },
    SET_LANGUAGES_USED(state, languages) {
      if(state.languagesUsed.length == 0) languages.map(language => { state.languagesUsed.push(language) });
    },
    SET_TRANSLATION_LANGUAGES(state, languages) {
      if(state.translationLanguages.length == 0) languages.map(language => { if(language.auto_translation == true) state.translationLanguages.push(language) });
    },
    SET_SUBTITLES_FOR_NEW_VIDEO(state, subtitles) {
      subtitles.forEach(subtitle => state.subtitleForNewVideo.push(subtitle.id));
    },
    UPDATE_PROJECTS_SELECTED(state, payload) {
      payload.map(project => state.projectsSelected.push(project))
    },
    UPDATE_SUBTITLES_TO_INCRUST(state, payload) {
      payload.isToAdd ?
        state.subtitlesToIncrust.push({video_id: payload.video_id, subtitle_id: payload.subtitle_id}) :
        state.subtitlesToIncrust.map((subtitle, i) => { if(subtitle.subtitle_id == payload.subtitle_id) state.subtitlesToIncrust.splice(i, 1) })
    },
    PRESELECT_SUBTITLES_TO_INCRUST(state) {
      state.projectsSelected.map(project => { 
        project.subtitles.map(subtitle => {
          if(subtitle.state == 10) state.subtitlesToIncrust.push({video_id: project.id, subtitle_id: subtitle.id})
        })
      })
    },
    UPDATE_SUBTITLE_TO_TRANSLATE(state, payload) {
      state.subtitleToTranslate.video_id = payload.video_id
      state.subtitleToTranslate.original_subtitle_id = payload.subtitle_id
    },
    UPDATE_LANGUAGE_TO_TRANSLATE(state, payload) {
      if(state.subtitleToTranslate.dest_lang.includes(payload.language_id)) {
        const subtitleIndex = state.subtitleToTranslate.dest_lang.indexOf(payload.language_id);
        state.subtitleToTranslate.dest_lang.splice(subtitleIndex, 1); 
      } else {
        state.subtitleToTranslate.dest_lang.push(payload.language_id);
      }
    },
    EMPTY_PROJECTS_SELECTED(state) {
      state.projectsSelected = []
    },
    EMPTY_SUBTITLES_TO_INCRUST(state) {
      state.subtitlesToIncrust = []
    },
    EMPTY_SUBTITLES_TO_TRANSLATE(state) {
      state.subtitleToTranslate = {
        video_id: null,
        original_subtitle_id: null,
        dest_lang: []
      }
    },
    SET_INCRUSTATIONS(state, incrustations) {
      state.incrustations = incrustations
    },
    SET_NUMBER_OF_INCRUSTATIONS(state, number) {
      state.numberOfIncrustations = number
    },
    SET_VIDEOS(state, videos) {
      state.videos = videos
    },
    SET_NUMBER_OF_VIDEOS(state, number) {
      state.numberOfVideos = number
    },
    SET_USER_PROJECTS_NAMES(state, projects) {
      state.userProjectsNames = []
      projects.forEach(project => state.userProjectsNames.push(project))
    },
    SET_CPL(state, payload) {
      state.cpl_selected = payload;
    },
    SET_LINE(state, payload) {
      state.line_selected = payload;
    },
  },
  actions: {
    // À implémenter pour passer uniquement par le store
    // getFilteredVideoProjects({ commit }, filteredDatas) {

    // },
    // getFilteredIncrustationsProjects({ commit }, filteredDatas) {

    // },
    getLanguages({ state, commit }) {
      AxiosLanguagesService.getLanguages()
      .then(response => {
        commit('SET_LANGUAGES', response.data.languages);
      }).catch(error => {
        console.log(error);
      });
    },
    getUsedLanguages({ state, commit }) {
      AxiosLanguagesService.getLanguagesUsed()
      .then(response => {
        commit('SET_LANGUAGES_USED', response.data.languages);
      }).catch(error => {
        console.log(error);
      });
    },
    getTranslationLanguages({ state, commit }) {
      AxiosLanguagesService.getLanguages()
      .then(response => {
        commit('SET_TRANSLATION_LANGUAGES', response.data.languages);
      }).catch(error => {
        console.log(error);
      });
    },
    postTranslation({ state }) {
      AxiosVideosService.postTranslation(state.subtitleToTranslate.video_id, state.subtitleToTranslate.original_subtitle_id, state.subtitleToTranslate.dest_lang, state.cpl_selected, state.line_selected)
      .then(response => {
        displayToast('success', 'Sous-titre envoyé en traduction')
        EventBus.$emit('update-projects')
      }).catch(error => {
        let errorMessages = error.response.data.errors.map(function(errorItem) {
          return errorItem.error;
        });
        let formattedErrorMessages = errorMessages.join('<br>');
        displayToast('error', formattedErrorMessages)
      })
    },
    postIncrustation({ commit }, dataToIncrust) {
      AxiosIncrustationService.postIncrustation(dataToIncrust.video_id, [dataToIncrust.subtitle_id])
      .then(response => {
        displayToast('success', 'Votre projet en cours d\'incrustation, retrouvez-le dans l\'onglet Mes Incrustation.')
        EventBus.$emit('update-projects')
      }).catch(error => {
        displayToast('error', 'Une erreur s\'est produite, veuillez réessayer')
      })
    },
    getIncrustation({ commit }, { perPage, currentPage }) {
      AxiosIncrustationService.getIncrustations(perPage, currentPage)
      .then(response => {
        commit('SET_INCRUSTATIONS', response.data.incrustations)
        commit('SET_NUMBER_OF_INCRUSTATIONS', response.data.number_incrustations)
      }).catch(error => {
        console.log(error);
      })
    },
    getIncrustationSearch({ commit }, { date, title, language, subtitle, project, perPage, currentPage }) {
      AxiosIncrustationService.getIncrustationsSearch(date, title, language, subtitle, project, perPage, currentPage)
      .then(response => {
        commit('SET_INCRUSTATIONS', response.data.incrustations)
        commit('SET_NUMBER_OF_INCRUSTATIONS', response.data.number_incrustations)
      }).catch(error => {
        console.log(error);
      })
    },
    getVideos({ commit }, { perPage, currentPage }) {
      AxiosVideosService.getVideos(perPage, currentPage)
      .then(response => {
        console.log(response.data)
        commit('SET_VIDEOS', response.data.videos)
        commit('SET_NUMBER_OF_VIDEOS', response.data.number_videos)
      }).catch(error => {
        console.log(error);
      })
    },
    getVideosSearch({ commit }, { date, title, language, subtitle, project, perPage, currentPage }) {
      AxiosVideosService.getVideosSearch(date, title, language, subtitle, project, perPage, currentPage)
      .then(response => {
        console.log(response.data)
        commit('SET_VIDEOS', response.data.videos)
        commit('SET_NUMBER_OF_VIDEOS', response.data.number_videos)
      }).catch(error => {
        console.log(error);
      })
    },
    getUserProjectsName({ commit }) {
      AxiosProjectService.getProjects()
      .then(response => {
        commit('SET_USER_PROJECTS_NAMES', response.data)
      })
    }
  },
};