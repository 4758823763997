<template>
  <div class="ui modal video-index-modal tiny" id="new-video-project-modal">
    <div class="header">
      <i class="import file icon big orange"></i>
      <h3>{{ t('project.new_project.new_video.btn_add') }}</h3>
    </div>
    <div v-show="firstStep">
      <i class="close icon" @click="closeModal()"></i>
      <form v-on:submit.prevent="uploadNewVideo()" class="content ui form">
        <CUploader
          ref="uploader-new-video-project"
          id="uploader-new-video-project"
          dropId="dropVideo-new-video-project"
          browseId="browseVideo-new-video-project"
          :fileAccepted="['3g2','3gp','3gp2','3gpp','4xm','amv','applehttp','asf','avi','avs','bink','c93','cdg','dirac','dpx','dv','dv1394','dvd','dvf','dxa','ffm','film','flv','gxf','h261','h263','h264','iff','ivf','lmlm4','m1a','m2a','m4a','m4b','m4p','m4r','m4v','matroska','mj2','mka','mks','mkv','mlv','mov','mp1','mp4','mpeg','mpeg1video','mpeg2video','mpegts','mpegtsraw','mpegvideo','mpg','mpv','mtv', 'mts','mvi','mxf','mxg','nsv','nut','nuv','ogm','ogv','ogx','oss','pva','r3d','raw','rm','rtp','rtsp','siff','smk','stream','svcd','swf','thp','ts','vc1','vob','vox','webm','wmv','wtv','xa','xmv']"
          @emitFilename="manageVideoFileAdded(arguments[0])"
          @emitFileDestroy="manageVideoFileRemoved()"
          @emitFilepath="postNewVideo(arguments[0])"
        >
        </CUploader>

        <div>
          <label for="project-name" class="form light label">{{ t('project.new_project.new_video.link_description') }}</label>
          <input
            :disabled="hasVideoFile"
            v-model="newVideo.url" 
            @change="newVideo.title = newVideo.url"
            :placeholder="t('project.new_project.new_video.link_placeholder')"
          >
        </div>

        <div class="selector-languages">
          <div class="required">
            <label class="form light label">{{ t('project.new_project.new_video.language_description') }}</label>
            <span>*</span>
          </div>
          <div class="languages-grid" :class="{'opened': languageGridOpened}">
            <div v-for="language in languages" :key="language.id" class="languages-grid-item" id="languages-grid-new-subtitle">
              <div class="languages-grid-item-container" @click="setVideoLanguage(language.id)">
                <div 
                  class="languages-grid-item-container-top languages-grid-new-subtitle"
                  :class="{ 'active' : language.id == newVideo.language_id }"
                >
                  <img
                    class="languages-grid-item-container-top-flag languages-grid-new-subtitle"
                    :src="require(`../../../assets/img/flags/${language.flag_iso}.png`)"
                  >
                </div>
                <p 
                  class="languages-grid-item-container-name"
                  :class="{ 'active' : language.id == newVideo.language_id }"
                >{{ language.name }}</p>
              </div>
            </div>
          </div>
          <div class="language-show-action" v-show="!languageGridOpened" @click="languageGridOpened = !languageGridOpened">
            <i class="plus square outline orange large icon"></i>
            <p>{{ t('project.new_project.new_video.see_more') }}</p>
          </div>
          <div class="language-show-action" v-show="languageGridOpened" @click="languageGridOpened = !languageGridOpened">
            <i class="minus square outline orange large icon"></i>
            <p>{{ t('project.new_project.new_video.see_less') }}</p>
          </div>
        </div>
        
        <div class="required">
          <label class="form light label">{{ t('project.projects.projects_tab.new_subtitle_title') }}</label>
          <span>*</span>
        </div>
        <input v-model="newVideo.title" :placeholder="t('project.projects.projects_tab.new_subtitle_title_placeholder')">
        
        <div class="video-project">
          <label class="form light label">{{ t('project.new_project.new_video.project_description') }}</label>
          <div class="ui fluid search selection dropdown new-video-project-projects-names">
            <input type="hidden" class="project-name-input">
            <i class="dropdown icon"></i>
            <div class="default text">{{ t('project.new_project.new_video.project_placeholder') }}</div>
            <div class="menu">
              <div class="item" :data-value="project.name" v-for="(project, i) in userProjectsNames" :key="i">
                {{ project.name }}
              </div>
            </div>
          </div>
        </div>
        
        <input type="submit" class="ui authot green button" :value="t('layout.shared.actions.send')">
      </form>
    </div>
    <div v-show="secondStep">
      <p v-html="t('project.new_project.loading_messages.description_upload_singular')"></p>
      <img class="img-upload" src="../../../assets/img/video/upload-img.svg" alt="">
      <div id="new-video-progressbar" class="ui small orange progress">
        <div class="bar">
          <div class="progress"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CUploader from '../../uploader/CUploader.vue'
import AxiosNewVideoService from '../../../services/video/new-video'
import AxiosProjectService from '../../../services/project/project'
import { EventBus } from '../../../packs/application'

export default {
  data() {
    return {
      newVideo: {
        title: null,
        original_file_path: null,
        url: null,
        language_id: null,

      },
      hasVideoFile: false,
      languageGridOpened: false,
      maxTitleLength: 25,
      firstStep: true,
      secondStep: false
    }
  },
  computed: {
    videoSubtitlesIds() {
      return this.$store.getters['projects/subtitles_for_new_video']
    },
    languages() {
      return this.$store.getters['projects/languages']
    },
    userProjectsNames() {
      return this.$store.getters['projects/user_projects_names']
    },
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    openModal() {
      EventBus.$emit('any-modal-opened');
      $("#new-video-project-modal").modal('show');
    },
    closeModal() {
      EventBus.$emit('any-modal-closed');
      $("#new-video-project-modal").modal('hide');
      this.languageGridOpened = false;
    },
    resetVideoModal() {
      this.newVideo = {
        title: null,
        original_file_path: null,
        url: null,
        language_id: null,
        project_name: null
      }
      this.hasVideoFile = false,
      this.languageGridOpened = false
      this.firstStep = true
      this.secondStep = false
    },
    manageVideoFileAdded(videoTitle) {
      this.newVideo.title = videoTitle
      this.hasVideoFile = true
      this.newVideo.url = null
    },
    manageVideoFileRemoved() {
      this.newVideo.title = null
      this.hasVideoFile = false
    },
    setVideoLanguage(languageId) {
      this.newVideo.language_id = languageId;
    },
    uploadNewVideo() {
      let uploader = this.$refs["uploader-new-video-project"]
      if(this.newVideo.language_id == null || this.newVideo.language_id == "") {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('project.projects.projects_tab.new_subtitle_error_language'),
          class : 'ui toast authot',
          className: {
            toast: 'ui toast authot'
          }
        });
      } else if(this.newVideo.title == null || this.newVideo.title == "") {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('project.projects.projects_tab.new_subtitle_error_title'),
          class : 'ui toast authot',
          className: {
            toast: 'ui toast authot'
          }
        });
      } else if(this.newVideo.url) {
        console.log("URL")
        this.postNewVideo(null)
      } else if(uploader.documents.length == 0) {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('project.projects.projects_tab.new_video_error_file'),
          class : 'ui toast authot',
          className: {
            toast: 'ui toast authot'
          }
        });
      } else {
        this.firstStep = false;
        this.secondStep = true;
        uploader.upload();
      };
    },
    postNewVideo(filePath) {
      this.newVideo.original_file_path = filePath
      this.newVideo.video_subtitles_id = this.videoSubtitlesIds
      this.newVideo.project_name = $('.ui.dropdown.new-video-project-projects-names').dropdown('get value');

      AxiosNewVideoService.postProject(this.newVideo)
      .then(response => {
        console.log(response)
        EventBus.$emit('update-projects');
        this.resetVideoModal()
        this.closeModal()
      }).catch(error => {
        this.firstStep = true;
        this.secondStep = false;
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('layout.shared.errors.error'),
          class : 'ui toast authot',
          className: {
            toast: 'ui toast authot'
          }
        });
      })
    }
  },
  mounted() {
    $('#new-video-project-modal').modal({
      inverted: true,
      closable: false,
    });

    $('.ui.search.languages.video').dropdown({
      placeholder: 'Indiquer une langue'
    });

    $('.ui.dropdown.new-video-project-projects-names').dropdown();

    this.$store.dispatch('projects/getUserProjectsName')
    EventBus.$on('file-progress', (resumablePercent) => $('#new-video-progressbar').progress({ percent: resumablePercent }))
  },
  components: {
    CUploader,
  }
}
</script>

<style scoped>
</style>